<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Industries Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        items: []
      },

      industry: {
        name: ""
      },

      showModal: false
    };
  },

  validations: {
    industry: {
      name: {required}
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('industries.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "createdAt", label: this.$t('table.created-at'), formatter: dateUtil.asDateTime},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    async loadIndustries() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const {data} = await axios.get(`/industry/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.table.perPage,
          filter: this.table.filter
        }
      });

      this.table.items = data.industries
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.items;
    },

    createOrEditIndustry() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.industry);

      axios.put(`/industry`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.industry.id ? "industries.edit.success" : "industries.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.$refs.table.refresh();
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteIndustry(industry) {
      this.submitted = true;

      const json = JSON.stringify({
        "id": industry.id,
        "deleted": true
      });

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.post(`/industry/change-status`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          this.$bvToast.toast(this.$t('industries.deleted.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, "")
    },

    hideModal() {
      this.submitted = false;
      this.showModal = false;
      this.industry = {
        name: ""
      };
    },

    editIndustry(industry) {
      this.industry = Object.assign({}, industry);
      this.showModal = true
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('industries.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success mb-2 px-4" @click="showModal = true">
                  <i class="mdi mdi-plus"></i> {{ $t('industries.create.button') }}
                </a>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadIndustries"
                :pagination-top="true"

            >
              <template v-slot:action="{ item }">
                <a @click="deleteIndustry(item)" class="clickable-element mr-3 text-danger"><i
                    class="mdi mdi-trash-can font-size-18 font-size-18"></i></a>
                <a @click="editIndustry(item)" class="clickable-element mr-3 text-primary"><i
                    class="mdi mdi-pencil font-size-18"></i></a>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t(industry.id ? 'industries.edit.title' : 'industries.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="name">{{ $t('industries.modal.name') }}</label>
          <input id="name" v-model="industry.name" :class="{ 'is-invalid': submitted && $v.industry.name.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.industry.name.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditIndustry" variant="success">
            {{ $t(this.industry.id ? 'industries.edit.button' : 'industries.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>